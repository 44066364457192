import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/cml_logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white shadow relative">
      <div className="container m-auto flex justify-between items-center p-0">
        <div className="text-2xl font-bold flex items-center">
          <a href="/" className="inline-block">
            <img src={logo} alt="CallMediLife Logo" className="h-12 inline-block ml-1 md:ml-3" />
          </a>
        </div>
        <div className="hidden md:flex flex-1 justify-end space-x-6">
          <Link to="/" className="hover:text-red-600">Home</Link>
          <Link to="/about" className="hover:text-red-600">About</Link>
          {/* <Link to="/services" className="hover:text-red-600">Services</Link> */}
        </div>
        <div className="md:hidden flex items-center m-5">
          <button onClick={toggleMobileMenu} className="text-red-900">
            {isMobileMenuOpen ? '✖' : '☰'}
          </button>
        </div>
        <div className="hidden md:flex h-full ml-5">
          <Link to="/contact" className="text-white font-bold px-8 py-4 text-lg bg-red-600 hover:bg-red-700 rounded-none h-full flex items-center justify-center">
            Contact Us <span className="ml-2 text-2xl">→</span>
          </Link>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow mt-2 p-4">
          <div className="flex flex-col space-y-2">
            <Link to="/" className="block hover:text-red-600">Home</Link>
            <Link to="/about" className="block hover:text-red-600">About</Link>
              <button className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded mt-2">
              <Link to="/contact">Contact</Link>
              </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;