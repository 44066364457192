import React from 'react';
import { FaClipboardCheck, FaBan, FaClock, FaStar, FaDollarSign } from 'react-icons/fa';

function CancellationAndRefund() {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">Cancellation and Refund Policies</h1>
      <div className="space-y-6">

        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
          <div className="flex items-center mb-4">
            <FaClipboardCheck className="text-green-600" size={30} />
            <h2 className="text-2xl font-semibold text-green-600 ml-3">Cancellation Before Delivery</h2>
          </div>
          <p className="text-gray-700">
            If the customer cancels before the delivery of the service through the customer portal online or at the call center, a 100% refund will be initiated.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
          <div className="flex items-center mb-4">
            <FaBan className="text-red-600" size={30} />
            <h2 className="text-2xl font-semibold text-red-600 ml-3">Cancellation by CallMediLife</h2>
          </div>
          <p className="text-gray-700">
            If CallMediLife initiates cancellation/rescheduling before delivery and the customer refuses, a 100% refund will be initiated. If the payment is not made, there will be no refund.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
          <div className="flex items-center mb-4">
            <FaClock className="text-yellow-600" size={30} />
            <h2 className="text-2xl font-semibold text-yellow-600 ml-3">Cancellation After Delivery Commencement</h2>
          </div>
          <p className="text-gray-700">
            If the customer cancels the service after delivery has commenced, CallMediLife will stop the service and initiate a full 100% refund of the payment made.
          </p>
          <p className="mt-2 text-gray-600 italic">Note: Cancellation should be backed with a valid reason.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
          <div className="flex items-center mb-4">
            <FaStar className="text-purple-600" size={30} />
            <h2 className="text-2xl font-semibold text-purple-600 ml-3">Satisfaction with Service</h2>
          </div>
          <p className="text-gray-700">
            If the customer is not satisfied with the service, a refund will not be initiated. CallMediLife may offer the customer the option to repeat the service at its discretion.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
          <div className="flex items-center mb-4">
            <FaDollarSign className="text-blue-600" size={30} />
            <h2 className="text-2xl font-semibold text-blue-600 ml-3">Incorrect Billing</h2>
          </div>
          <p className="text-gray-700">
            If the customer makes an incorrect billing, the exceeding amount will be returned. If the amount paid is less than the price, the customer will need to pay the remaining amount to CallMediLife.
          </p>
        </div>

      </div>
    </div>
  );
}

export default CancellationAndRefund;