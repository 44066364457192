import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import contact from '../assets/images/img_5.jpg';

const Contact = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showPopup, setShowPopup] = useState(false);
  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = (data) => {
    const { name, email, subject, message } = data;

    setSending(true);
    setErrorMessage('');

    // Sending email using SMTP.js
    window.Email.send({
      Host: "smtp.mailtrap.io",
      Username: "YOUR_USERNAME", // Replace with your Mailtrap username
      Password: "YOUR_PASSWORD", // Replace with your Mailtrap password
      To: 'sadaf.s@callmedilife.com', // The email to receive messages
      From: email, // The sender's email
      Subject: subject,
      Body: `Name: ${name}<br>Email: ${email}<br>Message: ${message}`
    })
    .then((response) => {
      console.log('Email sent successfully:', response);
      setShowPopup(true);
      setSending(false);
      reset();
    })
    .catch((error) => {
      console.error('Failed to send email:', error);
      setErrorMessage('Failed to send message. Please try again later.');
      setSending(false);
    });
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="relative text-white py-24">
        <img src={contact} alt="Background" className="absolute inset-0 w-full h-full object-cover" />
        <div className="absolute inset-0 bg-indigo-900 bg-opacity-50"></div>
        <div className="relative container mx-auto px-8 text-center z-10">
          <h1 className="text-5xl font-extrabold mb-6">CallMediLife HealthCare</h1>
          <p className="text-lg text-gray-200 max-w-2xl mx-auto">
            We bring healthcare services directly to your home with a focus on quality, affordability, and care. Experience healthcare, simplified.
          </p>
        </div>
      </section>

      <div className="max-w-7xl mx-auto lg:p-10 p-5 bg-white min-h-screen">
        {/* Information Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <div className="bg-indigo-50 shadow-md rounded-lg p-6 flex items-center border border-gray-200 hover:bg-indigo-100 transition duration-300 ease-in-out cursor-pointer">
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-md">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-indigo-950 text-lg" />
            </div>
            <div className="ml-4">
              <h3 className="text-sm font-medium text-gray-500 mb-1">Address</h3>
              <p className="text-gray-800 font-bold text-sm">Jay Antariksh, Makwana Road, Marol</p>
            </div>
          </div>

          <div className="bg-indigo-50 shadow-md rounded-lg p-6 flex items-center border border-gray-200 hover:bg-indigo-100 transition duration-300 ease-in-out cursor-pointer">
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-md">
              <FontAwesomeIcon icon={faPhoneAlt} className="text-indigo-950 text-lg" />
            </div>
            <div className="ml-4">
              <h3 className="text-sm font-medium text-gray-500 mb-1">Call Us Now</h3>
              <p className="text-gray-800 font-bold text-sm">+91 1800 266 3227</p>
            </div>
          </div>

          <div className="bg-indigo-50 shadow-md rounded-lg p-6 flex items-center border border-gray-200 hover:bg-indigo-100 transition duration-300 ease-in-out cursor-pointer">
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-md">
              <FontAwesomeIcon icon={faEnvelope} className="text-indigo-950 text-lg" />
            </div>
            <div className="ml-4">
              <h3 className="text-sm font-medium text-gray-500 mb-1">Mail Us Now</h3>
              <p className="text-gray-800 font-bold text-sm">customerservice@callmedilife.com</p>
            </div>
          </div>
        </div>

        {/* Map and Form in a Row */}
        <div className="flex flex-col md:flex-row gap-8">
          {/* Map */}
          <div className="flex-1 bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.317609405208!2d72.85570181521776!3d19.11685297366612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8ffdc9a1c9b%3A0x1c6e63d5eafc3c81!2sUnit%20No.%201002%2C%2010th%20Floor%2C%20Jay%20Antariksh%2C%20Thakur%20House%2C%20Makwana%20Road%2C%20Marol%20Andheri%20East%2C%20Mumbai%2C%20Maharashtra%20400059!5e0!3m2!1sen!2sin!4v1632340893458!5m2!1sen!2sin"
              className="w-full h-[830px] border-0"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>

          {/* Form */}
          <div className="flex-1 bg-white shadow-md rounded-lg lg:p-8 p-3 border border-gray-200">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Have Any Query? Please Contact Us!</h2>
            <p className="text-gray-600 mb-6">Have any questions or need assistance? We’re here to help. Contact us through the form below or via the details provided. Our team will respond to you as soon as possible.</p>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-lg font-semibold text-gray-700 mb-2">Your Name</label>
                <input
                  id="name"
                  type="text"
                  {...register('name', { required: 'Name is required' })}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                  placeholder="Your Name"
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
              </div>
              <div>
                <label htmlFor="email" className="block text-lg font-semibold text-gray-700 mb-2">Your Email</label>
                <input
                  id="email"
                  type="email"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email address'
                    }
                  })}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                  placeholder="Your Email"
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <label htmlFor="subject" className="block text-lg font-semibold text-gray-700 mb-2">Subject</label>
                <input
                  id="subject"
                  type="text"
                  {...register('subject', { required: 'Subject is required' })}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                  placeholder="Subject"
                />
                {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject.message}</p>}
              </div>
              <div>
                <label htmlFor="message" className="block text-lg font-semibold text-gray-700 mb-2">Message</label>
                <textarea
                  id="message"
                  {...register('message', { required: 'Message is required' })}
                  rows="5"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                  placeholder="Your Message"
                />
                {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message.message}</p>}
              </div>

              {/* Error message */}
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}

              {/* Send button */}
              <button
                type="submit"
                className="w-full py-3 px-6 bg-indigo-950 hover:bg-indigo-800 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Message'}
              </button>
            </form>

            {/* Popup */}
            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg p-8">
                  <h2 className="text-lg font-semibold text-gray-800 mb-4">Thank You!</h2>
                  <p className="text-gray-600">Your message has been sent successfully. We'll get back to you soon.</p>
                  <button
                    onClick={() => setShowPopup(false)}
                    className="mt-4 px-6 py-2 bg-indigo-950 hover:bg-indigo-800 text-white font-semibold rounded-lg"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;