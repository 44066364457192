import React from 'react';
import {
  FaInfoCircle, FaLock, FaFileAlt, FaExclamationTriangle, FaShoppingCart, FaUndo, FaRegFileAlt, FaTags, FaClipboardList, FaShieldVirus, FaFileContract, FaClipboardCheck
} from 'react-icons/fa';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">Terms & Privacy Policy</h1>
      <div className="space-y-8">
        {/* Introduction Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-red-700 mb-4">
            <FaLock className="mr-3 text-red-500" /> CALLMEDILIFE Respects The Privacy Of Each Customer
          </h2>
          <p className="text-gray-700 leading-relaxed">
            We, at CALLMEDILIFE, believe in protecting the personal information of our customers. The data collection, disclosure, and usage process is explained efficiently through our policy. Thus, ensuring total transparency in data protection. The personal information that is collected will be used as per described in the privacy policy.
          </p>
          <p className="text-gray-700 leading-relaxed">
            All the terms and phrases used in the Agreement will be as per the definitions established in this privacy policy, unless the context clearly indicates otherwise. E.g. "Data" describes any information (including Personal Information) provided to CALLMEDILIFE by a customer and service provider (Pathology Lab); and "Personal Information" refers to information about an identifiable customer, such as name, email address etc.
          </p>
        </section>

        {/* Data Collection Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-blue-700 mb-4">
            <FaClipboardList className="mr-3 text-blue-500" /> Data Collection
          </h2>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Customer Registration</h3>
          <p className="text-gray-700 leading-relaxed">
            Each customer gives access to specific data, including a login ID, a password, and an email address during registration. Our database stores the data to identify the customer during further visits. Customers may also be required to provide necessary billing information for online transactions. To ensure there is no online fraud happening, we automatically receive and record information on server logs from the customer's browser, including an IP address, CALLMEDILIFE cookie information, and the page requested. The websites that our customers access before and after visiting ours may or may not record their personal information, over which we don't exercise any control.
          </p>
        </section>

        {/* Data Usage Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-green-700 mb-4">
            <FaRegFileAlt className="mr-3 text-green-500" /> Data Usage
          </h2>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Call, Messages and Updates</h3>
          <p className="text-gray-700 leading-relaxed">
            We may SMS, call, or send email updates to the customers on their email accounts or phone numbers with regard to our services. This is usually done to ensure smooth delivery and procedures after the delivery services. Our new and existing customers will receive promotional messages. However, they can voluntarily unsubscribe.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">2. Cookies</h3>
          <p className="text-gray-700 leading-relaxed">
            In order to improve the user experience, we may use the customer's personal information. We may use cookies to manage and create a tailored website. Our diagnosis of problems is made easier by recording IP addresses. Thus, managing the website effectively.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">3. Targeted Content</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE may put out adverts based on your searches and data while you're at it. Rest assured, we do not share the personal information of our data with any of the advertisers except for the time when the customer has given their consent through the pop-up box on the ad. The websites that our customers access before and after visiting ours may or may not record their personal information, over which we don't exercise any control. Furthermore, the advertiser may store the customer's personal information if the customer is on their website.
          </p>
        </section>

        {/* Data Disclosure Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-lime-700 mb-4">
            <FaFileAlt className="mr-3 text-lime-500" /> Data Disclosure</h2>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">1. No Selling</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE sticks to its privacy policy and refrains from sharing, selling, or distributing any personal information of the customer.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">2. Marketing Purposes</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE may share general data statistics to advertisers for marketing purposes. However, it does not take the responsibility or gives any warranty for the security and privacy of third parties or any other linked websites.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">3. Third Party Supplier/ Agents/ Affiliates</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE may share customer's data with a third party supplier/agent-affiliate to provide certain services to the customer. The data shared will be limited and not hinder the privacy of the customer.
          </p>
          <p className="text-gray-700 leading-relaxed">
            We can also disclose data to designated third parties to resolve or investigate abuse complaints. Data can be released to assist in attempting to block an abusive customer or complain to their Internet Service Provider.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">4. Law Enforcement</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE may disclose data if required by the law (e.g. by a subpoena or any other judicial or administrative order) to protect the safety of any individual or the general public, to prevent violation of the agreement as well as the rights of CALLMEDILIFE or any third party.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">5. Change of Ownership</h3>
          <p className="text-gray-600">
            In case of a change of ownership (merger/ acquisition/ sale etc.), CALLMEDILIFE may reallocate the rights to data to the new owner. CALLMEDILIFE will send a notification before doing so to each Customer so that the customer may choose to modify/ delete their personal data as they deem fit.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">6. Employees</h3>
          <p className="text-gray-600">
            CALLMEDILIFE will share data with its employees as per the need of the job.
          </p>
        </section>

        {/* Security Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-teal-700 mb-4">
            <FaShieldVirus className="mr-3 text-teal-500" /> Security of Data</h2>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE applies advanced security measures to protect the customer data repository. Data is never disclosed unless the customer gives their consent. The website uses a secure server environment with a robust firewall and other advanced security measures to prevent interference or access from intruders. In addition, the customers are advised to maintain the confidentiality of their login details.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">1. Updating or Removing Data</h3>
          <p className="text-gray-700 leading-relaxed">
            The customers can update their personal information through the login and even deactivate their account if need be.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">2. Unauthorized Use</h3>
          <p className="text-gray-700 leading-relaxed">
            Any unauthorized use of CALLMEDILIFE information system is a violation of the agreement and certain laws, including Section 86 of the ECT Act. Such violations will be subject to civil criminal penalties. In addition, to avoid any unauthorized use, we can intercept, block, filter, delete, and disclose any communication over our information system.
          </p>
        </section>

        {/* Service Delivery & Payments Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-sky-700 mb-4">
            <FaShoppingCart className="mr-3 text-sky-500" /> Service Delivery & Payment</h2>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">1. Prices/Terms and Conditions of Payment</h3>
          <p className="text-gray-700 leading-relaxed">
            The service package charges specified on the website exclude any additional charges imposed by the banks or portals. The customer is required to pay for all of it. In case of payment defaults (payment deadlines not met, online transaction denied, any other payment default), CALLMEDILIFE will automatically cancel the service request, with a notification sent to the customer.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Custom made packages are potentially susceptible to increase in price and quantity in a reasonable amount.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">2. Delivery Dates</h3>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE adheres to the delivery time stipulated by the service provider. However, in case of hazards or delays in lab testing, these dates are subject to change.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">3. Passing of Risk</h3>
          <p className="text-gray-700 leading-relaxed">
            The lab providing the services will bear all risk of accidental loss, destruction or deterioration.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">4. Errors in Report</h3>
          <p className="text-gray-700 leading-relaxed">
            Any error in the report should be reported within 7 days of the issue of the receipt. The errors should be notified. Failing to do so will lead to no compensation.
          </p>
          <p className="text-gray-700 leading-relaxed">
            For loss or damage caused by delay due to ordinary negligence, we shall be liable for up to only 5% of the booking price agreed with us.
          </p>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">5. Applicable Laws and Judicial Forum</h3>
          <p className="text-gray-700 leading-relaxed">
            Substantive laws of the Republic of India shall apply to all legal relationships and transactions established by this purchase agreement.
          </p>
          <p className="text-gray-700 leading-relaxed">
            The judicial forum for both parties, including for bill of contract matters, is our principal place of business (registered office). If we become the plaintiff in litigation, then we are also entitled to bring an action in the jurisdiction of the customer's principal place of business (registered office).
          </p>
        </section>

        {/* Refund & Cancellation Policy Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-indigo-700 mb-4">
            <FaUndo className="mr-3 text-indigo-500" /> Refund & Cancellation Policy</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE offers its clients the option to cancel the order anytime before the beginning of the service. The refund will be initiated fully. A service is deemed to start as soon as the sample collector leaves for sample collection to the respective client chosen address. A service request shall not be cancelled once the service has started.
            </li>
            <li className="text-gray-700 leading-relaxed">
              In case of delay in services from the service provider, customers can ask for a full refund.
            </li>
            <li className="text-gray-700 leading-relaxed">
              If the customer isn't happy, refund of money will be initiated.
            </li>
            <li className="text-gray-700 leading-relaxed">
              In case of any issue with the reports, the client can inform the same within 1 day of the publication of the reports.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Any issues raised after 48 hours of reports publication will not be entertained since the samples collected will not be viable and would require fresh sample collection. This would be treated as a new order and would be chargeable at normal rates.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Refunds would be processed within 7-10 business days of a cancellation.
            </li>
          </ul>
        </section>

        {/* General Terms for Coupons Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-rose-700 mb-4">
            <FaRegFileAlt className="mr-3 text-rose-500" /> General Terms and Conditions for Coupon Payments</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE issues coupons and discounts to its users to reward them for their relationship with CALLMEDILIFE. Below are the terms and conditions of using the coupons. CALLMEDILIFE reserves the right to change/update these terms and conditions at any time.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The coupons have to be applied at the time of checkout in order to avail the benefits.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The coupon codes should be used before the validity expires. As the validity ends, the codes will be rendered ineffective.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupons can be used only once and are user-bound. Thus, the coupons are non-transferable, unless specified.
            </li>
            <li className="text-gray-700 leading-relaxed">
              A single coupon can be redeemed at one time.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Hard copy charges and sample collection charges if applicable will be applied on the net amount payable upon application of the coupon code, unless otherwise mentioned.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The coupons can only be redeemed on the mobile app of CALLMEDILIFE.
            </li>
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE reserves the right to change the terms and conditions of any coupon code at any time without prior intimation.
            </li>
          </ul>
        </section>

        {/* First Order Coupons Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-fuchsia-700 mb-4">
            <FaTags className="mr-3 text-fuchsia-500" /> CALLMEDILIFE First Order Coupons</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE issues booking coupons on the first order of each user. Users can use these coupons to save on their future orders on CALLMEDILIFE. Below are the terms and conditions for these coupons:
            </li>
            <li className="text-gray-700 leading-relaxed">
              The first order coupons can be redeemed only on the first order with CALLMEDILIFE.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Subsequently, users will get coupons equivalent to 10% of their first order value.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupon equivalent to 10% of the first order value will be issued in the form of coupon codes of various denominations cumulatively amounting to 10% of order value. For e.g. if the value is Rs.1078 then the customer will get 3 coupons, 2 of Rs.50 each and one coupon of Rs.8 taking the total to Rs.108 worth of coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupon will be issued in the form of codes of various denominations cumulatively amounting to Rs.200.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The cart value should be kept at Rs. 500 minimum in order to redeem the first order coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              All the general coupons terms and conditions mentioned above are applicable on first order coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              This offer is also applicable to users who have placed orders with CALLMEDILIFE in the past but have not received the first order coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The coupons can only be redeemed on the mobile app of CALLMEDILIFE.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupons are user-specific and are non-transferable.
            </li>
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE reserves the rights to change these conditions or withdraw the offer without any prior intimation.
            </li>
          </ul>
        </section>

        {/* Signup Coupons Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-blue-700 mb-4"> <FaInfoCircle className="mr-3 text-blue-500" /> Signup Coupons Terms and Conditions</h2>
          <p className="text-gray-700 leading-relaxed">
            CALLMEDILIFE is currently issuing signup coupons to every new user who downloads the app and signs up. Users can use these coupons to save on their future orders on CALLMEDILIFE. Below are the terms and conditions for these coupons:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li className="text-gray-700 leading-relaxed">
              Signup coupons will be issued only once per user irrespective of the number of times the user downloads the app through single or multiple app stores.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Not applicable on signup through the web or for orders placed through the phone channel.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Currently, users will get signup coupons worth Rs.200. However, this may change at CALLMEDILIFE's discretion without prior intimation.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupons will be credited to your CALLMEDILIFE account once your reports for the tests in the first order are uploaded. In case of partial sample collection, coupons will be issued for only the amount for which the sample was collected; remainder coupons will be issued as and when the sample collection happens. For partly canceled orders, only the proportionate worth of coupons will be issued and not the whole order value.
            </li>
            <li className="text-gray-700 leading-relaxed">
              The cart value should be kept at Rs. 500 minimum in order to redeem the first order coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              All the general coupons terms and conditions mentioned above are applicable to first order coupons.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupon will be issued in the form of codes of various denominations cumulatively amounting to Rs.200.
            </li>
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE reserves the right to change or withdraw this offer at their own discretion without any prior intimation.
            </li>
          </ul>
        </section>

        {/* Subscription Terms and Conditions Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-amber-700 mb-4"> <FaClipboardCheck className="mr-3 text-amber-500" /> Terms and Conditions for Subscriptions</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li className="text-gray-700 leading-relaxed">
              The subscription can only be availed for the specific test or package and is non-interchangeable. Added tests will be included with an overhead charge.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Subscription is linked to the customer's account and they can use the subscription to book those tests or package for themselves or for friends and family members. Subscription is non-transferable.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Subscription cannot be canceled. Individual tests/packages under the subscription can be rescheduled but cannot be canceled.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Additional tests requested at the time of sample collection should be paid for separately.
            </li>
            <li className="text-gray-700 leading-relaxed">
              In case a package or test is discontinued, CALLMEDILIFE will offer a substitute test of equivalent or greater value to the customer. If customers are not satisfied with the substitute offered, they will be offered a refund on a pro-rata basis.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Coupons are not applicable on subscription orders.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Subscription bought is for a defined period and will expire at the end of the period. Unused portions of the subscription will stand canceled at the end of the subscription.
            </li>
            <li className="text-gray-700 leading-relaxed">
              Subscription start date is the date on which subscription was bought and end date is dependent on the tenure for which subscription is bought.
            </li>
            <li className="text-gray-700 leading-relaxed">
              CALLMEDILIFE will make necessary efforts in the form of email and SMS communication to remind customers of their upcoming tests under the subscription. However, booking has to be made by the customer to avail the services under the subscription.
            </li>
          </ul>
        </section>

        {/* Terms and Conditions for Product and Service Page Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-fuchsia-700 mb-4"> <FaFileContract className="mr-3 text-fuchsia-500" /> T&C for Product and Service Page</h2>
          <p className="text-gray-700 leading-relaxed">
            Every user agrees and undertakes to abide by the following principles upon use of any platform. No user shall host, display, upload, modify, publish, transmit, update or share any information which:
          </p>
          <ul className="list-disc list-inside text-gray-700 leading-relaxed pl-6">
            <li>Belongs to another person and to which the user does not have any right to.</li>
            <li>Is grossly harmful, harassing, blasphemous; defamatory, obscene, pornographic, paedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever.</li>
            <li>Harms minors in any way.</li>
            <li>Infringes any patent, trademark, copyright or other proprietary rights.</li>
            <li>Violates any law for the time being in force.</li>
            <li>Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature.</li>
            <li>Impersonates another person.</li>
            <li>Contains software viruses or any other computer code, files or programs designed to Interrupt, destroy or limit the functionality of any computer resource.</li>
          </ul>
        </section>

        {/* Governing Law and Dispute Resolution Section */}
        <section className='p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl'>
          <h2 className="flex items-center text-2xl font-semibold text-orange-700 mb-4"> <FaExclamationTriangle className="mr-3 text-orange-500" /> Governing Law & Dispute Resolution</h2>
          <p className="text-gray-700 leading-relaxed">
            These Terms and Conditions shall be governed by the provisions of Indian law and shall be subject to the exclusive jurisdiction of courts situated at [Mumbai, Maharashtra], India. In the event of any dispute, controversy or claim arising out of, relating to, or in connection with these Terms and Conditions and Privacy Policy, or the formation, existence, negotiation or interpretation of these Terms and Conditions and Privacy Policy (Dispute), the representatives of CALLMEDILIFE and the User shall amicably resolve the Dispute in good faith, as soon as either party is apprised of the existence of a Dispute.
          </p>
          <p className="text-gray-700 leading-relaxed">
            In the event CALLMEDILIFE and the user are unable to resolve the Dispute within 30 days of address during registration. Our database stores the data to identify the customer during further them commencing discussions to amicably settle the dispute, the Dispute shall be referred to be resolved through arbitration. The arbitration proceedings shall be conducted in accordance with provisions of the (Indian) Arbitration and Conciliation Act, 1996. These Terms and Conditions and the rights and obligations of the parties shall remain in full force and effect pending the award in such arbitration proceeding.
          </p>
        </section>
    </div>
    </div>
  );
}

export default TermsAndConditions;
