import React from 'react';
import {
  FaInfoCircle, FaUserShield, FaDatabase, FaExchangeAlt, FaCookieBite,
  FaEnvelopeOpen, FaLock, FaAd, FaLink, FaFileAlt, FaExclamationTriangle
} from 'react-icons/fa';

const Privacy = () => {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-5xl font-extrabold text-gray-900 mb-12 text-center">Privacy Policy</h1>
      <div className="">
        <div className="space-y-10">
          {/* Introduction */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="introduction" className="flex items-center text-2xl font-semibold text-blue-700 mb-4">
              <FaInfoCircle className="mr-3 text-blue-500" /> Introduction
            </h2>
            <p className="text-gray-700 leading-relaxed">
              CallMediLife understands the importance of valuing privacy. It believes in maintaining confidentiality, security and integrity of information of all the users. The following privacy policies give an explanation on we collect, share, use, disclose, and protect personal information of the Users of the Services (Including the website visitors).
            </p>
            <p className="text-gray-700 leading-relaxed">
              This Privacy Policy is published in compliance of the (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (SPI Rules).
            </p>
            <p className="text-gray-700 leading-relaxed">
              Please read the policies carefully before indulging in the website in order to know how your personal information is treated.
            </p>
            <p className="text-gray-700 leading-relaxed">
              By visiting and/or accessing the Website, you agree to this Privacy Policy.
            </p>
          </section>

          {/* Collection of Personal Information */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="collection" className="flex items-center text-2xl font-semibold text-green-700 mb-4">
              <FaUserShield className="mr-3 text-green-500" /> Collection of Personal Information
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We may ask you for your personal information when you connect with us through calls, emails, or other means of contact in order to access our Services. You hereby consent to the collection of such information by CallMediLife. The information collected by us will involve:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Contact data (such as your full name, email address and phone number).</li>
              <li>Demographic data (such as your gender, your date of birth and your pin code).</li>
              <li>Data regarding your usage of the services and history of the appointments made by or with you through the use of Services.</li>
              <li>Data regarding your medical records history; and insurance data.</li>
              <li>Other information that you voluntarily choose to provide to us (such as information shared by you with us through emails or letters).</li>
            </ul>
            <p className="text-gray-700 leading-relaxed">
              The information collected may be deemed as ‘sensitive personal data or information’ under the SPI rules.
            </p>
          </section>

          {/* Controllers of Personal Information */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="controllers" className="flex items-center text-2xl font-semibold text-purple-700 mb-4">
              <FaDatabase className="mr-3 text-purple-500" /> Controllers of Personal Information
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Your personal data will be stored and collected by CallMediLife.
            </p>
          </section>

          {/* Purpose of Collection */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="purpose" className="flex items-center text-2xl font-semibold text-red-700 mb-4">
              <FaExchangeAlt className="mr-3 text-red-500" /> Purposes of Collection of Your Data
            </h2>
            <p className="text-gray-700 leading-relaxed">
              CallMediLife collects your information when you visit the website, register for a service, or when you use its products and services. You cease to be anonymous once you register with us. Furthermore, you may receive SMS(s) and notifications about our service at the number that you registered with.
            </p>
            <p className="text-gray-700 leading-relaxed">
              Hence, by registering you authorize the Company to send texts and email alerts to you with your login details and any other service requirements, including promotional mails and SMSs.
            </p>
            <p className="text-gray-700 leading-relaxed">
              Furthermore, in order to avail some of the Services, the Users may be required to upload copies of their prescriptions, on the Website and/or e-mail the same to Company in accordance with the Terms of Use and the prescriptions will be stored/disclosed by Company only in the manner specified in this Privacy Policy and the Terms of Use. The term personal information/data shall also include any such prescriptions uploaded or otherwise provided by Users.
            </p>
            <p className="text-gray-700 leading-relaxed">
              Furthermore, Company may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Register you as customer/user on the Website.</li>
              <li>Dealing with requests, enquiries or complaints and other customer care related activities; and all other general administrative and business purposes.</li>
              <li>Process your orders or applications and provision of products and services.</li>
              <li>Administer or otherwise carry out our obligations in relation to any agreement with our business partners/contractors.</li>
              <li>Research and development and for User administration (including conducting User surveys).</li>
              <li>Technical administration and customization of Website.</li>
              <li>To send you information about special promotions or offers (either offered by the Company or by its business partners).</li>
              <li>Improvement of Services and features on the Website.</li>
              <li>To send you notices, communications, offer alerts relevant to your use of the Services offered on this Website as otherwise provided in this Privacy Policy.</li>
            </ul>
          </section>

          {/* Information Sharing */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="sharing" className="flex items-center text-2xl font-semibold text-blue-700 mb-4">
              <FaExchangeAlt className="mr-3 text-blue-500" /> Information Sharing, Transfer and Disclosure
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We may need to disclose/transfer your personal information to the following third parties for the purposes mentioned in this Privacy Policy and the Terms of Use:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>To business partners and other service providers appointed by us for the purpose of carrying out services on our behalf under a contract.</li>
              <li>We may also share, sell, and/or transfer your personal information to any successor-in-interest as a result of a sale of any part of our business or upon the merger, reorganization, or consolidation of it with another entity.</li>
              <li>To government institutions/authorities to the extent required under the laws, rules, and regulations and or under orders of any relevant judicial or quasi-judicial authority.</li>
              <li>If otherwise required by an order under any law for the time being in force including in response to enquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences.</li>
              <li>In case of any contests or surveys conducted by the Company in which you participate, your information may be disclosed to third parties.</li>
            </ul>
          </section>

          {/* Cookies */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="cookies" className="flex items-center text-2xl font-semibold text-green-700 mb-4">
              <FaCookieBite className="mr-3 text-green-500" /> We Collect Cookies
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We may also receive and/or hold information about the User’s browsing history including the URL of the site that the User visited prior to visiting the website.
            </p>
            <p className="text-gray-700 leading-relaxed">
              The Website uses temporary cookies to store certain data that is used by the Company and its service providers for the technical administration of the Website, research and development, and for User administration.
            </p>
          </section>

          {/* Unsubscribe */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="unsubscribe" className="flex items-center text-2xl font-semibold text-red-700 mb-4">
              <FaEnvelopeOpen className="mr-3 text-red-500" /> Unsubscribe to E-mails
            </h2>
            <p className="text-gray-700 leading-relaxed">
              To opt out from receiving e-mails about announcements and marketing information, e-mail your request at [insert email].
            </p>
          </section>

          {/* Security */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="security" className="flex items-center text-2xl font-semibold text-purple-700 mb-4">
              <FaLock className="mr-3 text-purple-500" /> Security
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We employ appropriate technical and organizational security measures at all times to protect the information we collect from you. However, methods over the internet are never 100% secure. Therefore, we cannot guarantee its absolute security.
            </p>
          </section>

          {/* Third Party Advertising */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="advertising" className="flex items-center text-2xl font-semibold text-blue-700 mb-4">
              <FaAd className="mr-3 text-blue-500" /> Third Party Advertising
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We may use third-party advertising companies and/or ad agencies to serve ads when you visit our Website.
            </p>
          </section>

          {/* Links to Other Websites */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="links" className="flex items-center text-2xl font-semibold text-green-700 mb-4">
              <FaLink className="mr-3 text-green-500" /> Links to Other Websites
            </h2>
            <p className="text-gray-700 leading-relaxed">
              There might be affiliates or other sites linked to the Website. We are not responsible for the privacy of the personal information that you provide to these websites.
            </p>
          </section>

          {/* Changes in Privacy Policy */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="changes" className="flex items-center text-2xl font-semibold text-red-700 mb-4">
              <FaFileAlt className="mr-3 text-red-500" /> Changes in this Privacy Policy
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Company reserves the right to change this policy from time to time, with or without advance notice, at its sole discretion. We may update this privacy policy to reflect changes to our information practices. We encourage you to periodically visit this webpage.
            </p>
          </section>

          {/* Grievance Officer */}
          <section className="p-8 rounded-lg bg-white shadow-lg transition-all hover:shadow-xl">
            <h2 id="grievance" className="flex items-center text-2xl font-semibold text-blue-700 mb-4">
              <FaExclamationTriangle className="mr-3 text-blue-500" /> Grievance Officer
            </h2>
            <p className="text-gray-700 leading-relaxed">
              In case you have any grievances with respect to in accordance with applicable law on Information Technology and rules made thereunder, the name and contact details of the Grievance Officer.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;