import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaUserMd, FaComments, FaHeadset } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import partner1 from '../assets/images/img-02.png';
import partner2 from '../assets/images/img-03.png';
import partner3 from '../assets/images/img-04.png';
import partner4 from '../assets/images/img-05.png';
import partner5 from '../assets/images/img-06.png';
import partner6 from '../assets/images/img-07.jpg';
import partner7 from '../assets/images/img-08.png';
import partner8 from '../assets/images/img-09.png';
import partner9 from '../assets/images/img-10.png';
import partner10 from '../assets/images/img-11.png';
import partner11 from '../assets/images/img-12.png';
import partner12 from '../assets/images/img-13.png';
import CitiesMap from '../components/CitiesMap';
import Award from '../assets/images/msme_5000.jpg';
import { Link } from 'react-router-dom';
import heroSection from '../assets/images/heroSection.jpg';
import aboutUs1 from '../assets/images/img_6.jpg';
import aboutUs2 from '../assets/images/img_7.jpg';
import aboutUs3 from '../assets/images/aboutUs3.png';
import insurer_1 from '../assets/images/insurer_1.png';
import insurer_2 from '../assets/images/insurer_2.jpg';
// import insurer_3 from '../assets/images/insurer_3.jpg';
// import insurer_4 from '../assets/images/insurer_4.jpg';
import insurer_5 from '../assets/images/insurer_5.jpg';
import insurer_6 from '../assets/images/insurer_6.png';
import insurer_7 from '../assets/images/insurer_7.png';
import insurer_8 from '../assets/images/insurer_8.png';
import insurer_9 from '../assets/images/insurer_9.jpg';

function Home() {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-96 w-full mb-20">
        {/* Full-Width Hero Image */}
        <div className="absolute inset-0">
        <img
          src={heroSection}
          alt="Doctors"
          className="h-96 w-full object-cover"
        />
        <div className="absolute inset-0 bg-indigo-900 bg-opacity-50"></div>
        </div>

        {/* Overlapping Content */}
        <div className="relative z-10 flex flex-col justify-center h-full items-center text-white text-center p-16 mt-auto space-y-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-6">
            Good Health Is The Root Of All Happiness
          </h1>
        </div>

        {/* Overlapping Cards */}
        <div className="absolute bottom-[-60px] w-full flex justify-center items-center px-4">
          <div className="grid grid-cols-3 gap-4 text-center">
            <div className="bg-indigo-950 text-white p-3 sm:p-4 md:p-6 rounded-lg shadow-lg">
              <h2 className="text-xl sm:text-xl md:text-2xl font-bold">50+</h2>
              <p className="text-xs sm:text-sm md:text-base">Tests with discounts</p>
            </div>
            <div className="bg-indigo-950 text-white p-3 sm:p-4 md:p-6 rounded-lg shadow-lg">
              <h2 className="text-xl sm:text-xl md:text-2xl font-bold">60 Min</h2>
              <p className="text-xs sm:text-sm md:text-base">Home Sample Collection</p>
            </div>
            <div className="bg-indigo-950 text-white p-3 sm:p-4 md:p-6 rounded-lg shadow-lg">
              <h2 className="text-xl sm:text-xl md:text-2xl font-bold">20+</h2>
              <p className="text-xs sm:text-sm md:text-base">Labtest & Packages</p>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="container mx-auto px-4 py-16 grid grid-cols-1 lg:grid-cols-2 gap-4 items-center">
          {/* Left Column with Images */}
          <div className="relative flex justify-center items-center lg:ml-8 lg:justify-end lg:pr-20 pr-0"> {/* Added lg:justify-start for larger screens */}
            {/* Large Image */}
            <div className="rounded-lg overflow-hidden">
              <img
                src={aboutUs1}
                alt="Healthcare professionals"
                className="w-80 h-96 object-cover rounded-lg mx-auto lg:mx-0" 
              />
            </div>
            {/* Smaller Image overlapping the large image */}
            <div className="absolute bottom-[-40px] lg:bottom-[-40px] left-[50px] lg:left-[40px] rounded-lg overflow-hidden border-8 border-white shadow-lg">
              <img
                src={aboutUs2}
                alt="Doctor"
                className="w-56 h-64 object-cover rounded-lg mx-auto lg:mx-0" 
              />
            </div>
          </div>

          {/* Right Column with Text */}
          <div className="text-center lg:text-left lg:mr-24"> 
            <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 mt-20 lg:mt-0 rounded-full mx-auto lg:mx-0">
              About Us
            </button>
            <h2 className="text-3xl font-bold mt-4 text-gray-800">
              Why You Should Trust Us? Get To Know About Us!
            </h2>
            <p className="text-gray-600 mt-4">
              CallMediLife HealthCare is more than just a healthcare service. Starting a revolution in the industry, it brings together health service providers under a canopy of resources.
              We span across all the major cities in the country. CallMediLife Healthcare Services Pvt. Ltd has a gamut of in-house services that includes Physiotherapy, Nursing, Attendant/Home Health Aide, Doctor Visit, Lab Tests, New Born Baby & Mother Care, and more - all of them at the comfort of your home.
            </p>

            {/* List of Benefits */}
            <ul className="mt-6 space-y-3">
              <li className="flex items-center justify-center lg:justify-start text-gray-700">
                <FaCheckCircle className="w-6 h-6 text-indigo-950 mr-2" />
                Quality Health Care
              </li>
              <li className="flex items-center justify-center lg:justify-start text-gray-700">
                <FaCheckCircle className="w-6 h-6 text-indigo-950 mr-2" />
                Qualified Doctors
              </li>
              <li className="flex items-center justify-center lg:justify-start text-gray-700">
                <FaCheckCircle className="w-6 h-6 text-indigo-950 mr-2" />
                Medical Research Professionals
              </li>
            </ul>

            {/* Read More Button */}
            <button className="mt-8 px-6 py-3 bg-indigo-950 text-white font-semibold rounded-full shadow-lg hover:bg-indigo-600 mx-auto lg:mx-0">
              <Link to="/about">Read More</Link>
            </button>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="mt-5">
      <div className="bg-indigo-950 text-white mx-auto flex flex-col lg:flex-row items-center">
        {/* Left Side - Content */}
        <div className="lg:w-1/2 p-12">
          <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-2 rounded-full mb-4">
            Features
          </button>
          <h2 className="text-2xl lg:text-3xl font-bold mb-6">
            Why Choose Us
          </h2>
          <p className="text-white mb-6 text-sm">
            We bring healthcare services directly to your home with a focus on quality, affordability, and care. Experience healthcare, simplified.          </p>
          <div className="grid grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="flex items-center space-x-4">
              <div className="bg-white rounded-full p-3">
                <FaUserMd className="text-indigo-950 w-4 h-4" />
              </div>
              <div>
                <p className="text-white text-sm">Experience</p>
                <h3 className="text-sm font-bold text-white">Doctors</h3>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="flex items-center space-x-4">
              <div className="bg-white rounded-full p-3">
                <FaCheckCircle className="text-indigo-950 w-4 h-4" />
              </div>
              <div>
                <p className="text-white text-sm">Quality</p>
                <h3 className="text-sm font-bold text-white">Services</h3>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="flex items-center space-x-4">
              <div className="bg-white rounded-full p-3">
                <FaComments className="text-indigo-950 w-4 h-4" />
              </div>
              <div>
                <p className="text-white text-sm">Positive</p>
                <h3 className="text-sm font-bold text-white">Consultation</h3>
              </div>
            </div>
            {/* Feature 4 */}
            <div className="flex items-center space-x-4">
              <div className="bg-white rounded-full p-3">
                <FaHeadset className="text-indigo-950 w-4 h-4" />
              </div>
              <div>
                <p className="text-white text-sm">24 Hours</p>
                <h3 className="text-sm font-bold text-white">Support</h3>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side - Image */}
        <div className="lg:w-1/2">
          <img
            src={aboutUs3}
            alt="Doctors"
            className="lg:w-full lg:h-full w-80 h-80 pb-5 lg:pb-0"
          />
        </div>
      </div>
    </section>

      {/* Awards Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-4 text-center">
          <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 rounded-full">
            Achievements
          </button>
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 my-4">
            India 5000 Best MSME Awards
          </h2>
          <div className="flex flex-col items-center">
            <img src={Award} alt="Award" className="w-48 h-48 object-contain mb-4" />
            <p className="text-sm text-gray-600 mb-4">
              We are proud to be recognized with the MSME 5000 Award for our commitment to excellence and innovation in the industry.
            </p>
            <p className="text-sm text-gray-600 mb-4">
              This award reflects our dedication to quality service and our continuous efforts to support and empower the MSME sector.
            </p>
            <p className="text-sm text-gray-600">
              We strive to make a positive impact in our community and contribute to economic growth through our innovative solutions.
            </p>
          </div>
        </div>
      </section>

      {/* Associate Partners Section */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 text-center">
          <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 rounded-full">
            Partners
          </button>
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 text-center mb-8 mt-3">
            Our Associate Partners
          </h2>
          
          <div className="flex flex-wrap justify-center gap-8">
            {/* Partner 1 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner1}
                alt="Partner 1"
              />
            </div>

            {/* Partner 2 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner2}
                alt="Partner 2"
              />
            </div>

            {/* Partner 3 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner3}
                alt="Partner 3"
              />
            </div>

            {/* Partner 4 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner4}
                alt="Partner 4"
              />
            </div>

            {/* Partner 5 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner5}
                alt="Partner 5"
              />
            </div>

            {/* Partner 6 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner6}
                alt="Partner 6"
              />
            </div>

            {/* Partner 7 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner7}
                alt="Partner 7"
              />
            </div>

            {/* Partner 8 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner8}
                alt="Partner 8"
              />
            </div>

            {/* Partner 9 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner9}
                alt="Partner 9"
              />
            </div>

            {/* Partner 10 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner10}
                alt="Partner 10"
              />
            </div>

            {/* Partner 11 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner11}
                alt="Partner 11"
              />
            </div>

            {/* Partner 12 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {partner12}
                alt="Partner 12"
              />
            </div>
          </div>
          <hr className='mx-10 md:mx-96'/>
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 text-center mb-8 mt-5">
            Our Insurance Partners
          </h2>

          <div className="flex flex-wrap justify-center gap-8">
            {/* Partner 1 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_1}
                alt="Partner 1"
              />
            </div>

            {/* Partner 2 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_2}
                alt="Partner 2"
              />
            </div>

            {/* Partner 3 */}
            {/* <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_3}
                alt="Partner 3"
              />
            </div> */}

            {/* Partner 4 */}
            {/* <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_4}
                alt="Partner 4"
              />
            </div> */}

            {/* Partner 5 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_5}
                alt="Partner 5"
              />
            </div>

            {/* Partner 6 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_6}
                alt="Partner 6"
              />
            </div>

            {/* Partner 7 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_7}
                alt="Partner 7"
              />
            </div>

            {/* Partner 8 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_8}
                alt="Partner 8"
              />
            </div>

            {/* Partner 9 */}
            <div className="w-24 text-center w-1/4">
              <img
                className="mx-auto mb-4"
                src= {insurer_9}
                alt="Partner 9"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="mt-20 bg-white">
      {/* Heading at the Top */}
      <div className="text-center mb-8">
        <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 rounded-full">
          Testimonial
        </button>
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mt-3">What Our Customers Say!</h2>
      </div>

      {/* Testimonial Carousel */}
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true, el: ".custom-swiper-pagination" }}
        navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="max-w-3xl mx-auto relative"
      >
        {/* Testimonial 1 */}
        <SwiperSlide>
          <div className="text-center">
            <img
              className="w-24 h-24 rounded-full mx-auto mb-4"
              src="https://ui-avatars.com/api/?name=G+Venkateshwar&background=random&color=fff"
              alt="G Venkateshwar"
            />
            <p className="mb-4 text-gray-700">
              CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all the best.
            </p>
            <h4 className="font-bold text-gray-800">G Venkateshwar</h4>
          </div>
        </SwiperSlide>

        {/* Testimonial 2 */}
        <SwiperSlide>
          <div className="text-center">
            <img
              className="w-24 h-24 rounded-full mx-auto mb-4"
              src="https://ui-avatars.com/api/?name=Swapnesh+Shinde&background=random&color=fff"
              alt="Swapnesh Shinde"
            />
            <p className="mb-4 text-gray-700">
              CallMediLife gives us great competitive prices, and quick accurate results with online access to our reports. Always energetic, cheerful, helpful, accurate, and quick. A welcome anomaly in today's world.
            </p>
            <h4 className="font-bold text-gray-800">Swapnesh Shinde</h4>
          </div>
        </SwiperSlide>

        {/* Custom Pagination */}
        <div className="custom-swiper-pagination mt-12 flex justify-center space-x-3"></div>

        {/* Custom Navigation Arrows */}
        <div className="swiper-button-next absolute right-0 top-1/2 transform -translate-y-1/2 text-indigo-950"></div>
        <div className="swiper-button-prev absolute left-0 top-1/2 transform -translate-y-1/2 text-indigo-950"></div>
      </Swiper>
    </section>
    
    <div className='text-center mt-20'>
      <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 rounded-full">
        Pan India Service
      </button>
      <p className="text-2xl lg:text-3xl font-bold text-gray-800 my-3">We serve cities across India.</p>

      {/* Cities Map */}
      <CitiesMap />
    </div>
  </div>
  );
}

export default Home;