import React from 'react';
import { FaHandsHelping, FaShieldAlt, FaStethoscope } from 'react-icons/fa';
import { GiHealthNormal, GiMedicines, GiHeartBottle, GiMedicalPack } from 'react-icons/gi';
import { RiRocketLine, RiEyeLine } from 'react-icons/ri';
import about from '../assets/images/img_2.jpg';
import about2 from '../assets/images/about.png';

const About = () => {
  return (
    <div className="bg-gray-50 min-h-screen font-sans">

      {/* Hero Section */}
      <section className="relative text-white py-24">
        <img src={about} alt="about" className="absolute inset-0 w-full h-full object-cover" />
        <div className="absolute inset-0 bg-indigo-900 bg-opacity-50"></div>
        <div className="relative container mx-auto px-8 text-center z-10">
            <h1 className="text-5xl font-extrabold mb-6">CallMediLife HealthCare</h1>
            <p className="text-lg text-gray-200 max-w-2xl mx-auto">
            We bring healthcare services directly to your home with a focus on quality, affordability, and care. Experience healthcare, simplified.
            </p>
        </div>
      </section>

      {/* Who We Are Section */}
      <section className="container mx-auto py-16 px-8">
        <div className="flex flex-col lg:flex-row gap-12 items-center">
          <div className="flex-1 text-gray-700">
            <h2 className="text-4xl font-bold text-indigo-950 mb-4">Who We Are</h2>
            <p className="text-md mb-4">
                CallMediLife HealthCare is more than just a healthcare service. Starting a revolution in the industry, it brings together health service providers under a canopy of resources.
                We span across all the major cities in the country. CallMediLife Healthcare Services Pvt. Ltd has a gamut of in-house services that includes Physiotherapy, Nursing, Attendant/Home Health Aide, Doctor Visit, Lab Tests, New Born Baby & Mother Care, nurses, and physiotherapists - and all of them at the comfort of your home.
            </p>
            <p className="text-md mb-4">
                “An apple a day keeps the doctor away.” Well, there isn't anyone who hasn't come across this proverb. We wish you a million apples and endless days of prime health. Although, if at the slightest of possibility, you'd ever need a doctor, we don't want you to panic and run. CallMediLife will be the very support you'd need.
                We believe in the system and make it our responsibility to transform it by offering our users medical care through a responsible set of service providers managing the system effectively.
            </p>
            <p className="text-md">
                We present India's first hybrid map-based app which is multi-featured and compatible across multiple platforms.
                Furthermore, our employees go through a rigorous selection procedure supervised by some of the most experienced doctors in the industry.
                To add on to that, we facilitate affordable lab tests at home, making health care services more accessible to our customers. We wholeheartedly believe in our quest to provide our valuable customers more and more in-house health care services. We care for you!            </p>
          </div>
          <div className="flex-1">
            <img src={about2} alt="Healthcare services" className="rounded-lg h-full w-full shadow-lg hover:scale-105 transition-transform duration-300"/>
          </div>
        </div>
      </section>

      {/* Mission & Vision Section */}
      <section className="bg-indigo-100 py-16">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold text-indigo-950 mb-8 text-center">Our Mission & Vision</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col items-center">
              <RiRocketLine className="text-indigo-950 text-6xl mb-4"/>
              <h3 className="text-3xl font-semibold text-indigo-950 mb-4">Our Mission</h3>
              <p className="text-gray-700 text-lg text-center">
                We aim to create a quality-driven healthcare model with affordability at its core. Our mission is to bridge the gap between patients and top-tier healthcare services.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col items-center">
              <RiEyeLine className="text-indigo-950 text-6xl mb-4"/>
              <h3 className="text-3xl font-semibold text-indigo-950 mb-4">Our Vision</h3>
              <p className="text-gray-700 text-lg text-center">
                We strive to become the symbol of simplified healthcare, touching lives with empathy and care.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Values Section with React Icons */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold text-indigo-950 mb-8 text-center">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <FaHandsHelping className="text-indigo-950 text-6xl mb-4"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Quality</h3>
              <p className="text-gray-700">
                Our commitment to uncompromised quality at an affordable price sets us apart.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <FaShieldAlt className="text-indigo-950 text-6xl mb-4"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Efficiency</h3>
              <p className="text-gray-700">
                Through streamlined processes and stringent controls, we ensure maximum efficiency in our services.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <FaStethoscope className="text-indigo-950 text-6xl mb-4"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Care</h3>
              <p className="text-gray-700">
                Compassionate care lies at the core of everything we do, ensuring the best for our patients.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Service Guarantee Section with React Icons */}
      <section className="bg-blue-50 py-16 px-8">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-indigo-950 mb-8 text-center">Our Service Guarantee</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <GiHealthNormal className="text-indigo-950 text-6xl mb-4 mx-auto"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Guaranteed Healthcare</h3>
              <p className="text-gray-700">
                High-quality, affordable in-home healthcare, always.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <GiMedicines className="text-indigo-950 text-6xl mb-4 mx-auto"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Expert Healthcare Partners</h3>
              <p className="text-gray-700">
                Top-tier services across all locations, with trusted partners.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <GiHeartBottle className="text-indigo-950 text-6xl mb-4 mx-auto"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Corporate Healthcare</h3>
              <p className="text-gray-700">
                Premium healthcare packages tailored for businesses and employees.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-center">
              <GiMedicalPack className="text-indigo-950 text-6xl mb-4 mx-auto"/>
              <h3 className="text-2xl font-semibold text-indigo-950 mb-2">Comprehensive Care</h3>
              <p className="text-gray-700">
                Full spectrum of medical services and support.
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;