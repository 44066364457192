import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Coordinates of cities across India
const cityCoordinates = [
  { name: "Vijayawada", lat: 16.5062, lng: 80.6480 },
  { name: "Guwahati", lat: 26.1445, lng: 91.7362 },
  { name: "Patna", lat: 25.5941, lng: 85.1376 },
  { name: "Chandigarh", lat: 30.7333, lng: 76.7794 },
  { name: "Raipur", lat: 21.2514, lng: 81.6296 },
  { name: "Delhi", lat: 28.7041, lng: 77.1025 },
  { name: "New Delhi", lat: 28.6139, lng: 77.2090 },
  { name: "Mumbai", lat: 19.0760, lng: 72.8777 },
  { name: "Kolkata", lat: 22.5726, lng: 88.3639 },
  { name: "Bengaluru", lat: 12.9716, lng: 77.5946 },
  { name: "Hyderabad", lat: 17.3850, lng: 78.4867 },
  { name: "Chennai", lat: 13.0827, lng: 80.2707 },
  { name: "Pune", lat: 18.5204, lng: 73.8567 },
  { name: "Ahmedabad", lat: 23.0225, lng: 72.5714 },
  { name: "Surat", lat: 21.1702, lng: 72.8311 },
  { name: "Margoa", lat: 15.2993, lng: 74.1240 },
  { name: "Goa", lat: 15.2993, lng: 74.1240 },
  { name: "Panaji", lat: 15.4909, lng: 73.8278 },
  { name: "Mapusa", lat: 15.5907, lng: 73.8133 },
  { name: "Vasco", lat: 15.3969, lng: 73.8150 },
  { name: "Gandhidham", lat: 23.0750, lng: 70.1337 },
  { name: "Rajkot", lat: 22.3039, lng: 70.8022 },
  { name: "Vadodara", lat: 22.3072, lng: 73.1812 },
  { name: "Faridabad", lat: 28.4082, lng: 77.3179 },
  { name: "Gurgaon", lat: 28.4595, lng: 77.0266 },
  { name: "Jammu", lat: 32.7266, lng: 74.8570 },
  { name: "Srinagar", lat: 34.0837, lng: 74.7973 },
  { name: "Jamshedpur", lat: 22.8046, lng: 86.2020 },
  { name: "Ranchi", lat: 23.3569, lng: 85.3340 },
  { name: "Kochi", lat: 9.9312, lng: 76.2673 },
  { name: "Bhopal", lat: 23.2599, lng: 77.4126 },
  { name: "Indore", lat: 22.7197, lng: 75.8579 },
  { name: "Aurangabad", lat: 19.8762, lng: 75.3433 },
  { name: "Kolhapur", lat: 16.7050, lng: 74.2410 },
  { name: "Nagpur", lat: 21.1458, lng: 79.0882 },
  { name: "Nashik", lat: 19.9975, lng: 73.7900 },
  { name: "Navi Mumbai", lat: 19.0375, lng: 73.0296 },
  { name: "Palghar", lat: 19.7040, lng: 72.7572 },
  { name: "Thane", lat: 19.2183, lng: 72.9781 },
  { name: "Bhubaneswar", lat: 20.2961, lng: 85.8189 },
  { name: "Ludhiana", lat: 30.9009, lng: 75.8573 },
  { name: "Mohali", lat: 30.6954, lng: 76.6369 },
  { name: "Jaipur", lat: 26.9124, lng: 75.7873 },
  { name: "Secunderabad", lat: 17.4400, lng: 78.4985 },
  { name: "Coimbatore", lat: 11.0168, lng: 76.9558 },
  { name: "Ghaziabad", lat: 28.6692, lng: 77.4538 },
  { name: "Greater Noida", lat: 28.4845, lng: 77.5044 },
  { name: "Kanpur", lat: 26.4499, lng: 80.3319 },
  { name: "Lucknow ", lat: 26.8467, lng: 80.9462 },
  { name: "Noida", lat: 28.5355, lng: 77.3910 },
  { name: "Dehradun", lat: 30.3165, lng: 78.0322 },
  { name: "Hooghly", lat: 22.9072, lng: 88.5386 },
];

const CitiesMap = () => {
  const customIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <div className="h-96 w-full">
      {/* Centering the map on India with a zoom level focused on the country */}
      <MapContainer center={[20.5937, 78.9629]} zoom={5} className="h-full w-full">
        {/* Using OpenStreetMap tiles */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* Displaying markers for each city in the cityCoordinates array */}
        {cityCoordinates.map((city, index) => (
          <Marker key={index} position={[city.lat, city.lng]} icon={customIcon}>
            <Popup>{city.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default CitiesMap;