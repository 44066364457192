import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { AiFillSafetyCertificate } from 'react-icons/ai';

const Header = () => {
  return (
    <div className="hidden md:flex bg-blue-100 text-gray-700 py-2 text-sm px-2">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <span>📍</span>
            <span>Jay Antariksh, Makwana Road, Marol, Mumbai</span>
          </div>
          <div className="flex items-center space-x-1">
            <AiFillSafetyCertificate className="text-indigo-950" />
            <span>ISO 27001:2022 Certified</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a href="tel:+1234567890" className="flex items-center space-x-1">
            <span>📞</span>
            <span>+91 1800 266 3227</span>
          </a>
          <div className="flex items-center space-x-2">
            <a href="https://www.facebook.com/CallMediLife" className="text-blue-600"><FaFacebookF /></a>
            <a href="https://x.com/callmedilife" className="text-blue-400"><FaTwitter /></a>
            <a href="https://www.linkedin.com/company/callmedilife" className="text-blue-700"><FaLinkedinIn /></a>
            <a href="https://www.instagram.com/callmedilife" className="text-pink-600"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;